<template>
  <div class="loginPage">
    <div class="row" style="">
      <div class="col-12 col-md-4 loginPageLogo align-middle d-none d-md-block">
        <img src="@/assets/img/icones/nurse_104px.png" />
        Clínicas
        <p style="font-size: 12px">
          Sistema integrado de clínicas / telemedicina e hospitalar
        </p>

        <div style="width: 100%">
          <span v-for="rec in recursos" :key="rec.id" class="rec_efeito">
            <img
              :src="require('@/assets/img/icones/menu/' + rec.icon)"
              v-if="rec.iconType == 'png'"
            />
            <i :class="rec.icon" v-if="rec.iconType == 'fa'"></i>
            {{ rec.nome }}
          </span>
        </div>
      </div>

      <div class="col-12 col-md-8 login p-5">
        <div class="loginFormCenter">
          <b-form @submit="login">
            <h4>Acesso ao Sistema</h4>

            <b-form-group class="mt-5">
              <b-input
                autocomplete="none"
                aria-autocomplete="none"
                placeholder="Usuario"
                v-model="form.usuario"
                :state="validateState('usuario')"
                ref="usuario"
              ></b-input>
              <b-form-invalid-feedback
                >Informe um nome de usuário válido</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="mt-5">
              <b-input
                placeholder="Senha"
                type="password"
                ref="senha"
                v-model="form.senha"
                :state="validateState('senha')"
              ></b-input>
              <b-form-invalid-feedback
                >Informe uma senha</b-form-invalid-feedback
              >
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              size="lg"
              class="mt-5"
              block
              :disabled="loading"
            >
              <span v-if="!loading"><i class="fa fa-unlock"></i> Acessar</span>
              <span v-if="loading"
                ><b-spinner small size="sm" grow /> verificando...</span
              >
            </b-button>
            <div class="mt-3">
              <a href="#" class="text-center">Esqueceu a senha?</a>
            </div>
          </b-form>
          <hr />

          <div class="row">
            <div class="col-6">
              <b-select
                :options="['Servidor principal', 'Servidor BKP']"
                v-model="servidor"
              />
            </div>
            <div class="col-6">
              <connection-status />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/scss/login.scss";
import delay from "delay";
import ConnectionStatus from "../components/ConnectionStatus.vue";
export default {
  components: { ConnectionStatus },
  data() {
    return {
      recursos: [],
      form: {
        usuario: "",
        senha: ""
      },
      validator: [],
      loading: false,
      servidor: "Servidor principal"
    };
  },
  watch:{
    servidor:function(){
      this.$store.commit("changeDefaultAPIUrl",this.servidor == "Servidor principal"?this.$store.state.apiURLDef:this.$store.state.apiBKPURL);
    }
  },
  async mounted() {
    this.$refs.usuario.focus();

    this.recursos = [];
    await delay(800);
    for (let rec of this.$store.state.menu.filter(
      x => x.exibe && x.nome != "Sair"
    )) {
      await delay(300);
      this.recursos.push(rec);
    }

    // this.$refs.usuario.focus();
  },
  created() {
    this.$refs.usuario.focus();
  },
  methods: {
    async login(evt) {
      evt.preventDefault();
      this.loading = true;
      await this.$nextTick();

      console.log(1);
      this.validator = [];
      if (this.form.senha.trim() == "") {
        this.validator.push({ key: "senha", value: false });
        this.$refs.senha.focus();
      }
      if (this.form.usuario.trim() == "") {
        this.validator.push({ key: "usuario", value: false });
        this.$refs.usuario.focus();
      }

      if (this.validator.length <= 0) {
        await this.$store
          .dispatch("auth/auth", { tipo: "sistema", ...this.form })
          .then(data => {
            if (!data.success) {
              this.$swal
                .fire({
                  title: "Usuário e/ou senha inválido(s)!",
                  icon: "error"
                })
                .then(() => {
                  this.loading = false;

                  this.form.senha = "";
                  setTimeout(() => {
                    this.$refs.usuario.focus();
                    this.$refs.usuario.select();
                  }, 300);
                  // this.$refs.usuario.$el.selectAll();
                });
            } else {
              this.$router.push(this.recursos[0].link);
            }
          })
          .catch(err => {
            this.loading = false;

            console.log(err);
            this.$swal
              .fire({
                title: "Usuário e/ou senha inválido(s)!",
                icon: "error"
              })
              .then(() => {
                this.form.senha = "";
                setTimeout(() => {
                  this.$refs.usuario.focus();
                  this.$refs.usuario.select();
                }, 300);
                // this.$refs.usuario.selectAll();
              });
          });
      } else {
        this.loading = false;
      }

      return;
    },
    validateState(ref) {
      const fd = this.validator.find(x => x.key === ref);
      return !fd ? null : fd.value;
    }
  }
};
</script>